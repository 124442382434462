.configBubble {
  background-color: var(--main-bg-white-color);
  color: var(--primary-color);
  border-radius: 0.3rem;
  padding-left: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  cursor: pointer;
}

.configBubble:hover {
  background-color: var(--main-bg-white-color-hover);
}
