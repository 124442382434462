.earlyAccessSignUpForm {
  margin: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.earlyAccessSignUpForm-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailInput {
  padding: 12px 20px;
  margin: 4px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}

.resultMessage {
  margin: 6%;
}

@media screen and (max-width: 640px) {
  .earlyAccessSignUpForm-container {
    flex-direction: column;
  }
}
