.configInput {
  margin-top: 4%;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
}

.configInput-input {
  width: 80%;
  border-radius: 0.3rem;
}
