.form-container {
  background-color: var(--main-color);
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  /* background-color: var(--secondary-color); */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  /* flex-direction: column; */
  /* width: 50%;
  height: 50%;
  border-radius: 2%; */
}
