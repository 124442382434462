.imagePicker-container {
  position: absolute;
  background-color: var(--main-bg-white-color-hover);
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  min-height: 20%;
  border-radius: 0.3rem;
  padding: 2%;
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}

.imagePicker-images {
  width: 100%;
  margin-top: 10%;
  display: flex;
  flex-wrap: wrap;
}
