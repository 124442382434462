.configContractFuncs {
  display: flex;
}

.configContractFuncs-funcs {
  margin-top: 3%;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 16%;
  height: 12rem;
}

.configContractFuncs-button {
  margin-top: 2%;
  margin-bottom: 2%;
}
