.appButton {
  border: none;
  padding: 0.2% 1.5%;
  border-radius: 0.3rem;
  background-color: var(--secondary-color);
  color: var(--main-bg-white-color);
  font-size: var(--config-small-font-size);
  cursor: pointer;
  /*   
  position: absolute;
  top: 2%;
  right: 2%; */
}

.appButton:hover {
  background-color: var(--secondary-color-hover);
}
