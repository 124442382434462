.dashboardDappButton {
  border: none;
  padding: 0.4% 1.5%;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: var(--main-bg-white-color);
  font-size: var(--config-small-font-size);
  margin-top: 1%;
  margin-right: 1%;
  cursor: pointer;
}

.dashboardDappButton:hover {
  background-color: var(--primary-color-hover);
}

.dashboardDappButton-edit {
  border: none;
  padding: 0.4% 1.5%;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: var(--main-bg-white-color);
  font-size: var(--config-small-font-size);
  cursor: pointer;
  position: absolute;
  top: 28%;
  right: 10%;
}

.dashboardDappButton-edit:hover {
  background-color: var(--primary-color-hover);
}
