html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: aliceblue;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-family: Arial, Helvetica, sans-serif; */
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif; */
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  /* background-color: #151719; */
}

:root {
  --main-background-color: #151719;
  --primary-color: #25272a;
  --primary-color-hover: #323638;
  --secondary-color: #2d6df8;
  --secondary-color-hover: #1057f0;
  --grey-bg-color: rgb(233, 233, 233);
  --main-bg-color: #89daff;
  --main-bg-white-color: #ffffff;
  --main-bg-white-color-hover: #e9e9e9;
  --dark-gray-color: rgb(136, 133, 133);
  --small-font-size: 1.2rem;
  --medium-font-size: 1.6rem;
  --large-font-size: 1.8rem;
  --x-large-font-size: 2.8rem;

  --success-message-backgroundColor: #10bc00;
  --error-message-backgroundColor: #dd1a00;

  --config-xSmall-font-size: 1rem;
  --config-small-font-size: 1.2rem;
  --config-medium-font-size: 1.4rem;
  --config-large-font-size: 1.8rem;
  --config-x-large-font-size: 2.2rem;
  --main-red-color: rgb(200, 0, 0);
  --main-red-color-hover: rgb(161, 4, 4);
}
