.block {
  /* width: 30%; */
  width: 400px;
  min-height: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 2rem;
  /* justify-content: space-around; */

  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  margin: 50px;
}

.block-inBuilder {
  width: 40%;
  width: 400px;
  min-height: 2rem;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 2rem;
  /* justify-content: space-around; */

  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (max-width: 410px) {
  .block {
    width: 350px;
  }

  .block-inBuilder {
    width: 350px;
  }
}

@media screen and (max-width: 360px) {
  .block {
    width: 300px;
  }

  .block-inBuilder {
    width: 300px;
  }
}

/* @media screen and (max-width: 900px) {
  .block {
    width: 70%;
    height: 10%;
    margin-top: 10%;
  }

  .block-inBuilder {
    width: 80%;
    height: 10%;
    margin-top: 10%;
  }
} */
