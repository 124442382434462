.imagePickerImage {
  background-color: var(--primary-color);
  width: 25%;
  object-fit: contain;
  margin: 2%;
  cursor: pointer;
}

.imagePickerImage:hover {
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}
