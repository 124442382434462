.fontPicker-fonts {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 16%;
  height: 12rem;
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0.2), 0 6px 20px 0 rgb(0, 0, 0, 0.19);
}

.fontPicker-selectedFontContainer {
  display: flex;
}
