.configContainer {
  width: 80%;
  /* height: 100%; */
  background-color: rgb(94, 207, 211);
  margin-bottom: 10%;
}

.configContent {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  padding: 4%;
}
