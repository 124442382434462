.dappsDashboard {
  height: 100%;
  padding: 4%;
}

.dappsDashboard-heading {
  font-size: var(--config-large-font-size);
}

.dappsDashboard-container {
  background-color: var(--secondary-color);
  height: 100%;
  padding: 4%;
  border-radius: 0.3rem;
}

.dappsDashboard-container-loading {
  background-color: var(--secondary-color);
  height: 100%;
  padding: 4%;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logOutButton-dashboard {
  position: absolute;
  top: 4%;
  right: 4%;
}
