.configButtonWithIcon {
  display: flex;
  border: none;
  padding: 0.2% 1.5%;
  border-radius: 0.3rem;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-weight: bold;
  font-size: var(--config-xSmall-font-size);
  /* position: absolute;
  bottom: 4%;
  left: 2%; */
}

.configButtonWithIcon:hover {
  background-color: var(--secondary-color-hover);
}
