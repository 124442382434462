.dbCanvas {
  width: 100%;
  height: 100%;
  /* background-color: var(--dark-gray-color); */
}

.dbCanvas-loading {
  width: 100%;
  height: 100%;
  /* background-color: var(--dark-gray-color); */
  display: flex;
  justify-content: center;
  align-items: center;
}
