.dashboardDapp {
  padding: 2%;
  background-color: var(--main-bg-white-color);
  color: var(--primary-color);
  border-radius: 0.3rem;
}

.dapp-dappName {
  font-size: var(--config-medium-font-size);
  font-weight: 500;
}

.dapp-contractsContainer {
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 1%;
}
