.dynamicContractDataItem {
  margin-bottom: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--small-font-size);
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%; */
}
