.configStandardButton {
  border: none;
  padding: 0.4% 1.5%;
  border-radius: 0.3rem;
  background-color: var(--primary-color);
  color: var(--main-bg-white-color);
  font-size: var(--config-small-font-size);
  margin-top: 6%;
  margin-bottom: 6%;
  cursor: pointer;
}

.configStandardButton:hover {
  background-color: var(--primary-color-hover);
}
